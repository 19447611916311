<template>
  <descriptive-content
    :title="title"
    :layout="layout"
    :subtitle="subtitle"
    :description="description"
    :is-digital-signage="isDigitalSignage"
    :disableBackground="disableBackground"
    :hide="layout === 'homepage' || hide">
    <video
      :src="video"
      :poster="poster"
      v-if="video"
      class="video"
      :controls="!hideControls"
      :autoplay="autoplay"
      :muted="autoplay"
    />
  </descriptive-content>
</template>

<script>
  import ContentMixin from '../../mixin/content'

  export default {
    inheritAttrs: false,
    props: [
      'hide',
      'layout',
      'category',
      'autoplay',
      'hideControls',
      'disableBackground',
      'isDigitalSignage',
    ],
    computed: {
      video() {
        return this.category?.media?.links[0]?.href
      },
      poster() {
        return this.category?.media?.links[1]?.href
      },
    },
    mixins: [
      ContentMixin,
    ],
    inject: [
      '$helper'
    ],
  }
</script>

<style lang="scss" scoped>
  .video {
    @apply
    w-full
    max-w-full
    max-h-full;
  }
</style>